@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  background-color: #D3D3D3;
  min-height: 100vh;
  margin: 0px;
  background-image: url("../public/coldie-bg.jpg");
}

p {
  font-size: 1.5rem;
}

