.App {
    text-align: center;
    color: rgb(0, 0, 22);
    display: flex;
    flex-direction: column;
    max-width: 1620px;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem
}

.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 22);
}


.walletWrapper > div {
    background-color: none;
    border-radius: 0px;
}
.walletWrapper > div > button {
    background-color: rgb(0, 0, 22) !important;
}
.walletWrapper > button {
    background-color: rgb(0, 0, 22) !important;
    border-radius: 0px;
}

.walletWrapper > button:active > div {
    border-color: rgb(0, 0, 22);
    }